import React, { useState } from "react"
import HeaderLogoStudent from "./header-logo-student"
import speakerIcon from "../images/icons/fflh-dp-icon-speaker.svg"
import Link from "gatsby-link"

export default function StudentHeader() {
  return (
    <>
      <header className="c-student-header">
        <HeaderLogoStudent />
        <nav className="c-student-header__nav" aria-label="Meny">
          <button
            aria-label="Lyssna med browesealoud"
            title="Lyssna med browsealoud"
            type="button"
            onClick={() => window.toggleBar && window.toggleBar()}
            className="c-page-header__nav__icon"
          >
            <img
              className="c-page-header__nav__icon__image"
              src={speakerIcon}
              alt="Lyssna med browsealoud"
            />
          </button>
          {/*<ul className="c-student-header__nav__list">
            <li>
              <Link
                to="#berattelse"
                className="c-student-header__nav__list__link"
                activeClassName="-active"
              >
                Berättelse
              </Link></li>
            <li>
            <Link
              to="#kallor"
              className="c-student-header__nav__list__link"
              activeClassName="-active"
            >
              Källor
            </Link></li>
            <li>
              <Link
                to="#diskussionsfragor"
                className="c-student-header__nav__list__link"
                activeClassName="-active"
              >
                Diskussionsfrågor
              </Link></li>
          </ul>*/}
        </nav>
      </header>
    </>
  )
}
