import React from "react"
const whiteLogo = require("../images/icons/dp-logo-large-white.svg")
const blackLogo = require("../images/icons/dp-logo-large-black.svg")

export default function largeLogo({ modifier }) {
  return (
    <img
      className="c-footer__logo__img"
      src={modifier ? whiteLogo : blackLogo}
      alt="Forum för levande historia logo"
    />
  )
}
