import * as React from "react"
import { Helmet } from "react-helmet"

import StudentHeader from "../../../../../components/student-header"
import Footer from "../../../../../components/footer"
import ArticleHeader from "../../../../../components/article-header"

import "../../../../../scss/site.scss"
//import StickyCta from "../../../../../components/sticky-cta"

const Article = props => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Stockholmstidningen 29 Augusti 1943 | Demokrati Pågår </title>
        <link
          rel="canonical"
          href="https://www.demokratipagar.se/historiska-perspektiv/utan-demokratiska-rattigheter/johan-dimitri-taikon/stockholmstidningen-29-augusti-1943"
        ></link>
        <meta property="og:site_name" content="Demokrati Pågår"></meta>
        <meta property="og:type" content="article"></meta>
        <meta
          property="og:url"
          content="https://www.demokratipagar.se/historiska-perspektiv/utan-demokratiska-rattigheter/johan-dimitri-taikon/stockholmstidningen-29-augusti-1943"
        ></meta>
        <meta
          property="og:title"
          content="Stockholmstidningen 29 Augusti 1943"
        ></meta>
        <meta name="description" content="" />
        <script
          type="text/javascript"
          src="https://www.browsealoud.com/plus/scripts/3.1.0/ba.js"
          crossorigin="anonymous"
          integrity="sha256-VCrJcQdV3IbbIVjmUyF7DnCqBbWD1BcZ/1sda2KWeFc= sha384-k2OQFn+wNFrKjU9HiaHAcHlEvLbfsVfvOnpmKBGWVBrpmGaIleDNHnnCJO4z2Y2H sha512-gxDfysgvGhVPSHDTieJ/8AlcIEjFbF3MdUgZZL2M5GXXDdIXCcX0CpH7Dh6jsHLOLOjRzTFdXASWZtxO+eMgyQ=="
        ></script>
      </Helmet>
      <div className="c-body">
        <StudentHeader />
        <div role="main" id="main" className="o-main c-main-background">
          <article>
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-lg-9">
                  <ArticleHeader title="”Första skolan för romer avslutad”" />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-lg-7 c-article-content">
                  <p>
                    Mitt på lägerplatsen står 9 skolbänkar och en svart tavla.
                    Runt omkring står vagnar och tält. Där bor romerna. Idag är
                    det skolavslutning i lägret. Skolan startades av Sundberg.
                    Han visar oss runt.
                  </p>
                  <p>
                    Sundberg och Taikon gick till regeringen och bad om att få
                    starta en skola för romer. De fick 1500 kronor. De hoppas
                    att få fortsätta skolan nästa år. I Sverige bor det 500
                    romer. De flesta kan inte läsa. Det skapar stora problem för
                    dem.
                  </p>
                  <p>
                    Skolans fröken heter Hartmann. Hon berättar att skolan
                    funnits i två månader. Varje skoldag är 3 eller 4 timmar
                    lång. Skolan har 10 elever. De är mellan 6 och 42 år gamla.
                    De äldre eleverna arbetar ofta i hemmet. Då har de inte
                    kunnat gå i skolan. Alla har dock lärt sig något. Eleverna
                    är mycket intresserade.
                  </p>
                  <p>
                    Hela lägret är en familj. De är tillsammans 30 personer.
                    Marianne och Mauritz bor i lägret. Marianne har gått en
                    månad i skola förut. Men då hann hon inte lära sig läsa.
                    Båda barnen kan bara skriva sina namn med stora bokstäver.
                  </p>
                  <p>
                    Detta är den utbildning som Sverige tycker romerna ska ha.
                  </p>
                  <p>
                    Bildtext: Skolavslutning i lägret. Fröken Hartmann hjälper
                    en av eleverna. Marianne Taikon skriver på tavlan.
                  </p>
                </div>
              </div>
            </div>
          </article>
        </div>
        <Footer />
        {/*<StickyCta />*/}
      </div>
    </>
  )
}

export default Article
