import * as React from "react"
import Link from "gatsby-link"
import LargeLogo from "./footer-logo"

const Footer = props => {
  return (
    <footer
      className={props.modifier ? "c-footer " + props.modifier : "c-footer"}
      aria-label={props.modifier ? "Sidfot i öppen meny" : "Sidfot"}
    >
      <div className="container">
        <h2 className="c-footer__title">Om Demokrati pågår</h2>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-lg-5">
            <p>
              Demokrati pågår är ett digitalt klassrumsmaterial för åk 4-6. Med
              hjälp av materialet kan du jobba med att stärka förståelsen för
              demokratins värden och förutsättningar samt förmågan till att
              bidra i ett demokratiskt samtal hos dina elever.
            </p>

            <h3>Mer klassrumsmaterial</h3>
            <p>
              På&nbsp;
              <Link
                to="https://www.levandehistoria.se/klassrummet"
                activeClassName="-active"
              >
                levandehistoria.se/klassrummet
              </Link>
              &nbsp; hittar du mer material till undervisningen. Övningar,
              workshops, handledningar och mycket annat.
            </p>
          </div>
          <div className="col-xs-12 col-sm-6 col-lg-5">
            <h3>Forum för levande historia</h3>
            <p>
              Forum för levande historia är en myndighet som arbetar under
              Kulturdepartementet. Vi ska se till att fler arbetar med
              demokrati, tolerans och mänskliga rättigheter. Myndigheten ska
              särskilt informera om Förintelsen och om kommunistiska regimers
              brott mot mänskligheten så att de brotten inte händer igen. Vi ska
              också stärka människors vilja att verka för alla människors lika
              värde.
            </p>
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-2">
            <div className="c-footer__logo">
              <LargeLogo modifier={props.modifier} />
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
