import * as React from "react"

const ArticleHeader = props => {
  return (
    <header className="c-article-header">
      <h1 className="c-article-header__title">{props.title}</h1>
      <div className="c-article-header__preamble">
        {props.children}

        {props.datetime && props.estimate && <p>{props.estimate}</p>}
      </div>
    </header>
  )
}

export default ArticleHeader
